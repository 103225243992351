<template>
	<div id="official">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">微官网/班牌管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div class="font_14 nav-tab-item" @click="twacherTap(index)" :class="index == teacherIndex ? 'active' : ''" v-for="(item, index) in teacherMeun" :key="index">
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="content">
				<div class="u-f">
					<div class="right">
						<div class="title u-f-item u-f-jsb">
							<div>共{{ total }}条数据</div>
							<el-button size="small" @click="addVisible = true">添加账号</el-button>
						</div>
						<el-table :data="bannerList" height="83%" ref="multipleTable" tooltip-effect="dark" style="width: 100%;border: 1px solid #EEEEEE;">
							<el-table-column label="序号" type="index" width="65" align="center"></el-table-column>
							<el-table-column prop="account" label="账号" align="center"></el-table-column>
							<el-table-column prop="class.grade.name" label="年级" align="center"></el-table-column>
							<el-table-column prop="class.name" label="班级" align="center"></el-table-column>
							<el-table-column label="操作" align="center">
								<template slot-scope="scope">
									<el-button @click="openDialog(2, scope.row)" type="text" size="small">编辑</el-button>
									<el-button @click="delcourse(scope.row.id)" type="text" size="small" style="color:red;">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-right" style="padding: 15px 0;">
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="page"
								:page-size="limit"
								layout="total, prev, pager, next"
								:total="total"
							></el-pagination>
						</div>
					</div>
				</div>
			</div>

			<!-- 编辑 -->
			<el-dialog title="编辑账号" :visible.sync="editVisible" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							账号
						</div>
						<el-input v-model="account" placeholder="请输入账号" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							年级
						</div>
						<el-select v-model="grade_id" placeholder="请选择年级" style="width: 70%;" @change="gradeChange">
							<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							班级
						</div>
						<el-select v-model="class_id" placeholder="请选择班级" style="width: 70%;">
							<el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="colse" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit(2)">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 添加 -->
			<el-dialog title="添加账号" :visible.sync="addVisible" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							账号
						</div>
						<el-input v-model="account" placeholder="请输入账号" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							密码
						</div>
						<el-input v-model="password" placeholder="请输入密码" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							年级
						</div>
						<el-select v-model="grade_id" placeholder="请选择年级" style="width: 70%;" @change="gradeChange">
							<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							班级
						</div>
						<el-select v-model="class_id" placeholder="请选择班级" style="width: 70%;">
							<el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="colse" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit(1)">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			teacherMeun: [{ name: '资讯管理', id: 1 }, { name: '轮播图管理', id: 2 }, { name: '校园相册', id: 3 }, { name: '校园视频', id: 4 }, { name: '班牌账号', id: 5 }],
			teacherIndex: 4,
			addVisible: false,
			editVisible: false,
			total: 0,
			page: 1,
			limit: 10,
			bannerList: [],
			classList: '',
			class_id: '',
			account: '',
			password: '',
			gradeList:[],
			grade_id:''
		};
	},
	mounted() {
		this.getBannerList();
		this.gradeData();
	},
	methods: {
		// 年级列表
		gradeData() {
			this.$api.setting.getGradeList({}).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data.rows;
					this.gradeList = data;
				}
			});
		},
		gradeChange(){
			this.getClassList()
		},
		getClassList() {
			this.$api.setting.BrandUserlassList({
				grade_id:this.grade_id
			}).then(res => {
				if (res.data.code == 1) {
					this.classList = res.data.data;
				}
			});
		},
		twacherTap(index) {
			this.teacherIndex = index;
			if (index == 0) {
				this.$router.push('/official');
			} else if (index == 1) {
				this.$router.push('/setBanner');
			} else if (index == 2) {
				this.$router.push('/schoolPhoto');
			} else if (index == 3) {
				this.$router.push('/schoolVideo');
			} else if (index == 4) {
				this.$router.push('/account');
			}
		},
		colse(){
			this.password = '';
			this.account = '';
			this.grade_id = '';
			this.class_id = '';
			this.editVisible = false;
			this.addVisible = false;
		},
		// 关闭弹窗
		handleClose(done) {
			this.password = '';
			this.account = '';
			this.grade_id = '';
			this.class_id = '';
			done();
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
      this.getBannerList()
		},
		openDialog(type, item) {
			if (type == 1) {
				this.addVisible = true;
			} else {
				this.editVisible = true;
				this.id = item.id;
				this.account = item.account;
				this.class_id = '';
				this.grade_id = item.class.grade_id;
				this.getClassList()
			}
		},
		submit(type) {
			let api = this.$api.setting.addBrandUser;
			let data = {
				account: this.account,
				class_id: this.class_id
			};
			if(type==1){
				data.password = this.password
			}
			if (type == 2) {
				data.id = this.id;
				api = this.$api.setting.editBrandUser;
			}
			api(data).then(res => {
				if (res.data.code == 1) {
					if (type == 1) {
						this.$message.success('添加成功');
					} else {
						this.$message.success('修改成功');
					}
					this.password = '';
					this.account = '';
					this.grade_id = '';
					this.class_id = '';
					this.addVisible = false;
					this.editVisible = false;
					this.getBannerList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		delcourse(id) {
			let _this = this;
			_this
				.$confirm('确定要删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				})
				.then(() => {
					_this.$api.setting
						.delBrandUser({
							id: id
						})
						.then(res => {
							if (res.data.code == 1) {
								_this.$message.success('删除成功');
								_this.getBannerList();
							} else {
								_this.$message.error(res.data.msg);
							}
						});
				});
		},
		getBannerList() {
			this.$api.setting
				.brandUserList({
					page: this.page,
					pagesize: this.limit
				})
				.then(res => {
					if (res.data.code == 1) {
						this.bannerList = res.data.data.rows;
						this.total = res.data.data.total;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		}
	}
};
</script>

<style lang="scss">
#official {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 15px;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 78vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 78vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				height: 70vh;
				overflow-y: auto;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.active {
					background-color: #e4f1ff;
					color: #007aff;
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 78vh;
			width: 100%;
			padding: 25px 50px 0 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 20%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.upload-file {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 50px;
				color: #8c939d;
				width: 150px;
				height: 150px;
				line-height: 150px;
				text-align: center;
			}
			.avatar {
				width: 150px;
				height: 150px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
